.lounascard {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 400px;
    top: 35%;
    right: 50%;
    background: #F7F5F3;
    margin-right: 50px;
    border-radius: 50px;
    box-shadow: 0 0px 10px rgba(0, 119, 255, 1);
    animation: floatIn 0.5s forwards; /* ページが開いたときのアニメーションを設定 */
    transition: transform 0.5s ease;
    cursor: pointer;
}

.project-title {
    text-align: center;
    margin-top: 180px;
    font-size: 60px;
}

.lounas_link{
    position: absolute;
    bottom: -10px;
    font-size: 20px;
    font-family: 'Noto Sans jp', sans-serif;

}

.prinlup{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 400px;
    top: 35%;
    left: 50%;
    margin-left: 50px;
    background: #F7F5F3;
    border-radius: 50px;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.5);
    animation: floatIn 0.5s ease forwards;
}

.prinlup-card{
    margin-bottom: -30px;
}

.lounascard:hover{
    transform: scale(1.1);
    transition: 0.5s;
}

@keyframes floatIn{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

@media only screen and (max-width: 1000px) {

    .project-card {
        display: grid;
        grid-auto-flow: row;
        place-items: center;
    }

    .project-title{
        margin-top: 100px;
        font-size: 40px;
    }

    .lounascard{
        position: relative;
        width: 280px;
        height: 280px;
        right: 0;
        margin-right: 0;
        margin-bottom: 20px;
        top: 0;
        transition: none;
    }

    .lounascard:hover{
        transform: none;
        transition: none;
    }

    .projects-lounas-card {
        width: 130px;
    }

    .prinlup{
        position: relative;
        width: 280px;
        height: 280px;
        left: 0px;
        top: 0;
        margin-left: 0;
        margin-top: 20px;
        margin-bottom: 40px;
    }

    .projects-prinlup-card {
        width: 180px;
    }

    .lounas_link {
        font-size: 17px;
    }
    
}

