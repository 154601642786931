.top {
    width: 100%;
    height: 100vh;
    display: grid;
    place-items: center;
}

.topmessage {
    font-weight: 700;
    font-size: 40px; 
    margin: 20px;
    animation: slideInFromBottom 0.6s ease forwards;
}

@keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(10px); /* 上に移動 */
    }
  }
.page-down {
    animation: bounce 2s infinite;
    height: 40px;
    width: 40px;
}

.page-down-container {
    position: absolute;
    bottom: 10%;
    display: flex; 
    align-items: center;
    animation: slideInFromBottom 0.6s ease forwards;
}

.page-down-text {
    margin-left: 10px;
    font-size: 20px;
}

@keyframes slideInFromBottom {
    from {
      transform: translateY(30%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .scroll-page {
    display: grid;
    margin-top: 50px;
    place-items: center;
  }

  .scroll-page-card {
    display: grid;
    width: 80%;
    margin: 20px;
    place-items: center;
  }

  .sub-title {
    margin-bottom: 0;
    font-size: 40px;
  }

  .main-title {
    margin-top: 0px;
    font-size: 50px;
    margin-bottom: 10px;
  }

  .asetes-image {
    margin: 50px;
    width: 700px;
  }

  .join-us {
    display: grid;
    border-radius: 50px;
    margin-top: 50px;
    margin-bottom: 50px;
    width: 90%;
    height: 300px;
    background-color: #FFEBC8;
    place-items: center;
    grid-auto-flow: column;
  }

  .join-us-text {
    padding: 50px;
    color: #501300;
    wight: 700;
    font-size: 60px;
    left: 0;
  }

  .sub-title-text {
    font-size: 25px;
    font-weight: 600;
    text-align: center;
  }

  .join-us-sub {
    display: grid;
    place-items: center;
  }

  .link-contact {
    display: block;
    width: 50%;
    padding: 10px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    background-color: #501300;
    color: #fff;
    text-align: center;
  }

  .contact-button {
    width: 80%;
    padding: 10px;
    border-radius: 20px;
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    border: none;
    background-color: #501300;
    cursor: pointer;
  }

  .speakerdeck-iframe {
    width: 800px;
    aspect-ratio: 16/9;
  }



  .iput{
    font-size: 25px;
  }

  @media only screen and (max-width: 1000px) {
    .topmessage {
        font-size: 25px;
        margin: 50px;
    }
    .page-down-container {
      margin-left: 30px;
    }
    .page-down-text {
      font-size: 15px;
    }
    .page-down {
      height: 30px;
      width: 30px;
    }

    .scroll-page {
      display: grid;
      width: 100%;
    }

    .scroll-page-card {
      width: 90%;
    }

    .sub-title {
      font-size: 20px;
    }

    .main-title {
      font-size: 20px;
    }

    .sub-title-text{
      font-size: 14px;
    }

    .asetes-image {
      width: 100%;
      margin: 20px;
    }

    .join-us {
      height: 230px;
      grid-auto-flow: row;
      border-radius: 30px;
      margin-top: 30px;
      
    }

    .join-us-text {
      padding: 0px;
      margin-bottom: 0px;
      font-size: 30px;
    }

    .link-contact {
      width: 80%;
      padding: 0px;
      font-size: 15px;
    }

    .contact-button {
      padding: 10px;
      font-size: 15px;
    }

    .iput{
      margin-top: -30px;
      width: 80%;
      font-size: 15px;
    }
    .speakerdeck-iframe {
      width: 400px;
      aspect-ratio: 16/9;
    }

    @media only screen and (max-width: 500px){
      .speakerdeck-iframe {
        width: 100%;
        aspect-ratio: 16/9;
      }
    }
  }