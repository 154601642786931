.timeline-all {
    width: 90%;
    height: 100%;
    margin-left: 50px;
    margin-top: 100px;
    display: grid;
    grid-auto-flow: row;
    place-items: center;
}

.day-2024-4-27,
.day-2024-3-1,
.day-2024-1-4,
.day-2023-10-16,
.day-2023-9-30,
.day-2023-7-1 {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}

.timeline-item {
    width: 400px;
    align-items: flex-start;
    margin-left: 100px;
    animation: fadeIn 0.5s ease forwards;
    opacity: 0;  
}

@keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  

.date-container {
    display: flex; /* 要素を横に並べる */
    align-items: center; /* 垂直方向に中央揃え */
}

.date {
    width: 20px; 
    height: 20px; 
    margin-right: 15px;
    background-color: #0066FF;
    border-radius: 50%; 
    left: -7px;
    position: relative; 
}

.date-container p {
  margin:  1; 
  padding: 1; 
}

.day {
    font-size: 25px;
}

.content {
    display: flex;
    border-left: 3px solid #707070; 
    padding-left: 30px; 
    font-size: 20px;
}

.image-timeline {
    display: flex;
    width: 500px;
    height: 500px;
    align-items: center;
    justify-content: center;
    animation: fadeIn 0.5s ease forwards;
    opacity: 0; 
}

.app-home-page,
.lounas-presents {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}



@media only screen and (max-width: 1100px) {

    .timeline-all {
        width: 100%;
        margin: 0px;
        margin-top: 100px;
    }

    .timeline-item {
        width: 280px;
        margin: 0px;
    }

    .day-2024-4-27,
    .day-2024-3-1,
    .day-2024-1-4,
    .day-2023-10-16,
    .day-2023-9-30,
    .day-2023-7-1 {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .day {
        font-size: 20px;
    }

    .image-timeline {
        width: 250px;
        height: 250px;
    }

    .content {
        padding-left: 20px; 
        font-size: 15px;
    }

    .app-home-page {
        width: 240px;
    }
    
    .prinlup-card {
        width: 170px;
    }
    
    .lounas-card {
        width: 140px;
    }
    
    .lounas-presents {
        width: 250px;
    }

    .app-dev {
        width: 150px;
    }
}




  

