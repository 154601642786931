.container-wrapper {
    display: grid;
    place-items: center;
    grid-auto-flow: column;
    height: 100vh;
    background-color: #FFEBC8;

}

.title {
    align-items: flex-start;
    justify-content: center;
    background-color: #FFEBC8;
    display: flex;
    flex-direction: column;
    font-family: "Noto Sans JP", sans-serif;
  }

  .sayhello, .contact {
    margin: 0; 
  }
  
  
  .sayhello{
      font-size: 90px;
      color: #501300;
  }
  
  .contact{
      margin-top: 20px;
      font-size: 25px;
      color: #000000;
  }

  .form-field-name, .form-field-mail, .form-field-message {
    margin-top: 20px;
    margin-bottom: 5px;
  }

  
  .form {
    flex-direction: column;
  }

  .label {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-top: 20px;
  }

  .input {
    width: 100%;
    padding: 10px;
    font-size: 20px;
    font-family: "Noto Sans JP", sans-serif;
    border: 1px solid #fff;
    border-radius: 10px;
  }

  .input_message {
    width: 100%;
    height: 200px;
    padding: 10px;
    font-size: 20px;
    font-family: "Noto Sans JP", sans-serif;
    border: 1px solid #fff;
    border-radius: 10px;
    resize: none;
  }

  .btns-send {
    margin-top: 10px;
    width: 107%;
    padding: 12px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }
  
  .btns-send:hover{
    background-color: #0056b3;
  }

  @media only screen and (max-width: 750px) {
    .container-wrapper {
      display: grid;
      grid-auto-flow: row;
    }

    .sayhello{
      font-size: 40px;
      margin-top: 50px;
    }

    .title {
      margin-bottom: 40px;
    }

    .container {
      width: 60%;
      height: 40%;
      margin: 50px;
    }

    .contact{
      font-size: 14px;
      margin-top: 5px;
    }

    .form {
      margin-top: 0px;
    }

    .label{
      font-size: 18px;
    }

    .form-field-name, .form-field-mail, .form-field-message {
      margin-top: 10px;
      margin-bottom: 5px;
    }

    .heading{
      font-size: 25px;
      margin-top: -200px;
    }

    .input {
      padding: 5px;
      border-radius: 7px;
      font-size: 20px;
    }

    .input_message {
      padding: 5px;
      border-radius: 7px;
      height: 150px;
      font-size: 14px;
    }

    .btns-send {
      padding: 10px;
      font-size: 14px;
      margin-top: 10px;
    }

  }