@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;200;300;400;500;600;700;800;900&display=swap');

header {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(50px);
  }

  
  .logo {
    display: flex;
    text-decoration: none;
    width: 300px;
    margin-left: 50px;
  }

  nav ul {
    list-style-type: none;
    display: flex;
    margin-right: 50px;
  }
  
  nav ul li {
    margin-right: 50px;
  }
  
  nav ul li a {
    position: relative;
    font-size: 1.3em;
    color: #5B5B5B;
    text-decoration: none;
    font-weight: 500;
    marin-right: 300;
  }

  .home-page nav ul li a::after{
    background-color: #FFFFFF;
  }

  .home-page nav ul li a:hover::after{
    transform: scaleX(1);
    transform-origin: left;
  }

  nav ul li a::after{
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #000000;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform .5s;
  }
  
  nav ul li a:hover::after{
    transform-origin: left;
    transform: scaleX(1);
  }

  .menu-toggle {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
}

  .bar {
      width: 25px;
      height: 3px;
      background-color: #fff;
      margin: 4px 0;
}

  .mobile-menu {
      display: none;
    }

  @media only screen and (max-width: 900px) {
    nav ul li {
      margin-right: 20px;
    }
  }

  @media only screen and (max-width: 750px) {
    nav ul {
      display: none;
    }

    .logo {
      margin-left: 20px;
      width: 250px;
    }

    .mobile-menu {
      display: block;
      margin-right: 50px;
    }

    .DrawerIcon {
      fontSize: 50px;
    }
  }


